import React from "react"
// import generateRandomPropsForMySparkle from "./generateRandomPropsForMySparkle"
// import MySparkleSvg from "./mySparkleSvg"

import {
    useRandomInterval,
    // usePrefersReducedMotion,
    useReducedMotion, range
} from "../../../utils/functions"
import SparkleSvg from "./sparkleSvg"
// import {random} from "Math"

const DEFAULT_COLOR = '#FFC700';


const generateSparkleDataRandomly = (color, svgOrigWidth, sizeFactor) => {

    const sparkle = {
        id: String(Math.random(10000, 99999)),
        createdAt: Date.now(),
        color,
        // size: Math.random(10, svgOrigWidth)*10,
        // size: Math.random(0.1*svgOrigWidth, svgOrigWidth)*100,
        size: Math.random(0.1 * svgOrigWidth, svgOrigWidth) * sizeFactor,
        style: {
            top: Math.random(0, 100) * 100 + '%',
            left: Math.random(0, 100) * 100 + '%',
            zIndex: 2,
        },
    };

    return sparkle;
};


const styleWrapper = {
    display: "inline-block",
    position: "relative",
    zIndex: 5,
    width: "90%"
}


const styleChildWrapper = {
    position: "relative",
    zIndex: 1,
    fontWeight: "bold"
}

const defaultSvgPath = 'M26.5 25.5C19.0043 33.3697 0 34 0 34C0 34 19.1013 35.3684 26.5 43.5C33.234 50.901 34 68 34 68C34 68 36.9884 50.7065 44.5 43.5C51.6431 36.647 68 34 68 34C68 34 51.6947 32.0939 44.5 25.5C36.5605 18.2235 34 0 34 0C34 0 33.6591 17.9837 26.5 25.5Z'
const defaultSvgViewBox = "0 0 68 68"

const SparklesRandomly = ({ svgPath = defaultSvgPath, svgViewBox = defaultSvgViewBox, svgOrigWidth = 20, sizeFactor = 100, color = DEFAULT_COLOR, number = 10, children, ...delegated }) => {

    const [sparklesData, setSparkles] = React.useState(() => {
        return range(number, number + 10, 1).map(() => generateSparkleDataRandomly(color, svgOrigWidth, sizeFactor));
    });

    // const prefersReducedMotion = usePrefersReducedMotion();
    const prefersReducedMotion = useReducedMotion();


    useRandomInterval(
        () => {
            const sparkleData = generateSparkleDataRandomly(color, svgOrigWidth, sizeFactor);
            const now = Date.now();
            const nextSparklesData = sparklesData.filter(sp => {
                const delta = now - sp.createdAt;
                return delta < 750;
            });
            nextSparklesData.push(sparkleData);
            setSparkles(nextSparklesData);
        },
        prefersReducedMotion ? null : 50,
        prefersReducedMotion ? null : 450
    );

    return (
        <span style={styleWrapper} {...delegated}>
            {sparklesData.map(sparkle => (
                <SparkleSvg
                    key={sparkle.id}
                    color={sparkle.color}
                    size={sparkle.size}
                    style={sparkle.style}
                    svgPath={svgPath}
                    svgViewBox={svgViewBox}
                />
            ))}
            <strong style={styleChildWrapper} >{children}</strong>
        </span>
    );
};


export default SparklesRandomly;
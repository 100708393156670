import React, { useState, useEffect } from 'react'
import { Wheel } from 'react-custom-roulette'

const dProps = {
  data: [
    { option: '0', style: { backgroundColor: 'green', textColor: 'black' } },
    { option: '1', style: { backgroundColor: 'white' } },
    { option: '2' },
  ],
  backgroundColors: [ 'darkgrey', 'lightgrey' ],
  textColors: [ 'black' ],
  outerBorderColor: 'black',
  innerRadius: 0,
  innerBorderColor: "black",
  innerBorderWidth: 0,
  radiusLineColor: 'black',
  radiusLineWidth: 5,
  fontSize: 20,
  perpendicularText: false,
  textDistance: 60

}


const RouletteWheel1 = (props) => {

  const [ isMounted, setIsMounted ] = useState()

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) return null

  const {
    onStopSpinning,
    mustSpin,
    prizeNumber,
    data = dProps.data,
    ...otherProps
  } = props


  return (
    <Wheel
      mustStartSpinning={mustSpin}
      prizeNumber={prizeNumber}
      data={data}
      onStopSpinning={onStopSpinning}
      {...otherProps}
      style={{
        display: "inline-block",
        // width: "100px" 
      }}
    />
  )
}

export default RouletteWheel1


const svgData={
    mySpark1:{
        path:"M80 160C80 160 80 111.242 80 80C111.242 80 160 80 160 80C160 80 112.298 85.1451 93.5764 66.4236C74.8549 47.702 80 0 80 0C80 0 80 48.7581 80 80C48.7581 80 0 80 0 80C0 80 47.702 74.8549 66.4236 93.5764C85.1451 112.298 80 160 80 160Z",
        viewBox:"0 0 160 160",
        width: 160,
        height:160,
    },
    mySpark2:{
        path:"M80 0C80 0 80 48.7581 80 80C111.242 80 160 80 160 80C160 80 112.298 74.8549 93.5764 93.5764C74.8549 112.298 80 160 80 160C80 160 80 111.242 80 80C48.7581 80 0 80 0 80C0 80 47.702 85.1451 66.4236 66.4236C85.1451 47.702 80 0 80 0Z",
        viewBox:"0 0 160 160",
        width: 160,
        height:160,
    }
}


export default svgData

/* <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" fill="none">
<rect width="160" height="160" fill="white"/>
<path d="M80 160C80 160 80 111.242 80 80C111.242 80 160 80 160 80C160 80 112.298 85.1451 93.5764 66.4236C74.8549 47.702 80 0 80 0C80 0 80 48.7581 80 80C48.7581 80 0 80 0 80C0 80 47.702 74.8549 66.4236 93.5764C85.1451 112.298 80 160 80 160Z" fill="#FFC700"/>
<path d="M80 0C80 0 80 48.7581 80 80C111.242 80 160 80 160 80C160 80 112.298 74.8549 93.5764 93.5764C74.8549 112.298 80 160 80 160C80 160 80 111.242 80 80C48.7581 80 0 80 0 80C0 80 47.702 85.1451 66.4236 66.4236C85.1451 47.702 80 0 80 0Z" fill="#FFC700"/>
</svg> */
import React, { useState } from 'react'
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import useTheme from '@mui/material/styles/useTheme';
import Button from "@mui/material/Button";
import styled from '@mui/material/styles/styled';

import { v4 as uuidv4 } from 'uuid';

import SparklesRandomly from "./sparklesRandomly/sparklesRandomly"
import svgData from "../../images/svg/svgData"
import RouletteWheel1 from "./rouletteWheel1"

const StyledDivOutWrapWheel = styled("div")({
})


const styleSxOutWrapWheel = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  "& .directWrapWheel": {
    display: "grid",
    justifyItems: "center",
    "& img": {
      width: "17%"
    },
  },
  "& .btn": {
    fontSize: [ "smaller", "small" ],
    margin: 2,
    justifySelf: "center"
  },
  "& .modal": {
    display: 'flex',
    padding: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  "& .paper": {
    width: "300px",
    backgroundColor: "primary2.main",
    borderRadius: '5px',
    border: '2px solid #000',
    boxShadow: 5,
    padding: [ 2, 4, 3 ],
    "& .textVyhra": {
      color: "primary2.contrastText"
    },
  },
}



const MyRouletteWheel1 = () => {

  const theme = useTheme()
  const [ mustSpin, setMustSpin ] = useState(false);
  const [ prizeNumber, setPrizeNumber ] = useState(0);

  const rootRef = React.useRef(null);
  const [ modalOpen, setModalOpen ] = React.useState(false);
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * myWheelOptions.data.length)
    setPrizeNumber(newPrizeNumber)
    setMustSpin(true)
  }

  const onStopSpinning = () => {
    setMustSpin(false)
    setModalOpen(true)
  }

  const myWheelOptions = {
    data: [
      { option: 'zľava 100€', style: { backgroundColor: theme.palette.primary2.light, textColor: theme.palette.primary2.contrastText } },
      { option: '0', style: { backgroundColor: theme.palette.warning.dark, textColor: theme.palette.warning.contrastText, boxShadow: theme.shadows[ 5 ] } },
      { option: 'zľava 100€', style: { backgroundColor: theme.palette.primary2.dark, textColor: theme.palette.primary2.contrastText } },
      { option: '0', style: { backgroundColor: theme.palette.info.light, textColor: theme.palette.info.contrastText } },
      { option: 'zľava 100€', style: { backgroundColor: theme.palette.primary.light, textColor: theme.palette.primary.contrastText } },
      { option: '0', style: { backgroundColor: theme.palette.error.light, textColor: theme.palette.error.contrastText } },
      { option: 'zľava 100€', style: { backgroundColor: theme.palette.primary.dark, textColor: theme.palette.primary.contrastText } },
      { option: '0', style: { backgroundColor: theme.palette.error.dark, textColor: theme.palette.error.contrastText } },
      { option: 'zľava 100€', style: { backgroundColor: theme.palette.secondary.dark, textColor: theme.palette.secondary.contrastText } },
      { option: '0', style: { backgroundColor: theme.palette.error.light, textColor: theme.palette.error.contrastText } },
      { option: 'zľava 100€', style: { backgroundColor: theme.palette.secondary.light, textColor: theme.palette.secondary.contrastText } },
      { option: '0', style: { backgroundColor: theme.palette.error.dark, textColor: theme.palette.error.contrastText } },
    ],
    backgroundColors: [ theme.palette.primary.dark, theme.palette.primary.light ],
    textColors: [ theme.palette.text.main ],
    outerBorderColor: theme.palette.secondary.dark,
    innerRadius: 3, //0..100
    innerBorderColor: "black",
    innerBorderWidth: 1,
    radiusLineColor: theme.palette.error.main,
    radiusLineWidth: 1,
    fontSize: 16,
    perpendicularText: false,
    textDistance: 60, //0.100
    onStopSpinning: onStopSpinning,
    mustSpin: mustSpin,
    prizeNumber: prizeNumber
  }


  const messageTitle = [ 1, 3, 5, 7, 9, 11 ].includes(prizeNumber) ? "Ahh, žiadna výhra." : `Vaša výhra: ${myWheelOptions.data[ prizeNumber ].option}`
  const message = [ 1, 3, 5, 7, 9, 11 ].includes(prizeNumber) ? "Ale, skús ešte raz." : `V prebiehajúcej akcii pre objednanie web stránok môže byť uplatnená ${myWheelOptions.data[ prizeNumber ].option}. Pri objednaní, prosím uveďte kód ${uuidv4(new Date().getMonth())}.`


  return (
    <StyledDivOutWrapWheel
      sx={styleSxOutWrapWheel}
      ref={rootRef} >

      <div
        className="directWrapWheel"
      >

        <RouletteWheel1 {...myWheelOptions} />
      </div>
      <Button onClick={handleSpinClick}
        variant="contained"
        color="secondary"
        className="btn"
      >Nájdi svoju výhru</Button>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={modalOpen}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className="modal"
        container={() => rootRef.current}
        onClose={handleModalClose}
        closeAfterTransition
      >
        <Fade in={modalOpen}>
          <div
            className="paper"
          >
            <SparklesRandomly
              svgPath={svgData.mySpark1.path}
              svgViewBox={svgData.mySpark1.viewBox}
              svgOrigWidth={svgData.mySpark1.width}
              sizeFactor={20}
              number={10}
            >

              <div id="server-modal-title">{messageTitle}</div>
              <p id="server-modal-description" className="textVyhra">{message}</p>
            </SparklesRandomly>

          </div>
        </Fade>
      </Modal>
    </StyledDivOutWrapWheel>
  )
}

export default MyRouletteWheel1

